<template>
  <div>
    <v-row>
      <v-col>
        <v-chip
          class="ma-2"
          label
          text-color="primary"
          color="primary"
          outlined
          right
          to="/components/tool/tab-2"
          @click="alert = !alert"
        >
          {{ $t("message.how_to_register") }}
        </v-chip>
        <!-- <material-alert
          :value="alert"
          color="white"
          dark
          transition="scale-transition"
        >
          <a
            :href="sns.twitter"
            target="_blank"
          >
            {{ $t("message.how_to_register_concept_1") }}
            <v-icon
              class="mr-1"
              small
              color="#55acee"
            > mdi-twitter </v-icon></a>
          <p>
            {{ $t("message.how_to_register_concept_2") }}
            <v-icon
              class="mr-1"
              small
              color="#55acee"
            >
              mdi-twitter
            </v-icon>
          </p>
          <p>{{ $t("message.how_to_register_concept_3") }}</p>
        </material-alert> -->

        <!-- <v-spacer /> -->

        <v-chip
          v-clipboard:copy="tag"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          dark
          class="ma-2"
          label
          text-color="primary"
          color="primary"
          outlined
        >
          <v-icon left>
            mdi-content-copy
          </v-icon>
          {{ $t("message.copy_tag_concept") }}
        </v-chip>
        <v-chip
          class="ma-2"
          label
          text-color="primary"
          color="primary"
          outlined
          right
          to="/components/concept-tool"
          @click="alert = !alert"
        >
          {{ $t("message.concept_tool") }}
        </v-chip>
      </v-col>
    </v-row>
    <v-data-iterator
      :items="items"
      sort-by="update_date"
      :search="search"
      :sort-desc="sortDesc"
      :footer-props="{ 'items-per-page-options': [20, 30, 40] }"
      :items-per-page="20"
      :page="page"
      :options="options"
      @options="optionsSave"
      @update:page="scrollTop"
    >
      <template #header>
        <v-toolbar
          dark
          color="gray darken-3"
          class="mb-1"
        >
          <v-text-field
            v-model="search"
            clearable
            flat
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-magnify"
            :label="$t('message.search_from_concept_tweets')"
          />
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-spacer />
            <v-btn-toggle
              v-model="sortDesc"
              mandatory
            >
              <v-btn
                large
                depressed
                color="gray"
                :value="false"
              >
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn
                large
                depressed
                color="gray"
                :value="true"
              >
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
        </v-toolbar>
      </template>

      <template #default="props">
        <!-- <v-select
        v-model="selectedId"
        item-text="user.name"
        item-value="user.name"
        :items="items"
        label="ユーザーで絞る"
        v-on:change="changeUser(selectedId)"
      /> -->
        <v-row dense>
          <v-col
            v-for="item in props.items"
            :key="item.id_str"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-badge
              v-if="item.update_date >= targetDate"
              offset-x="20"
              offset-y="28"
              left
              dot
            />
            <tweet
              :id="item.id_str"
              error-message=" "
              :options="{ align: 'center' }"
              @TweetError="notShow(item)"
            >
              <spinner />
            </tweet>
          </v-col>
        <!-- <ads /> -->
        <!-- <infinite-loading
          slot="append"
          force-use-infinite-wrapper=".el-table__body-wrapper"
          @infinite="infiniteHandler"
        /> -->
        </v-row>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
  import { Tweet } from 'vue-tweet-embed'
  import axios from 'axios'
  import Spinner from 'vue-simple-spinner'
  //   import Ads from './Ads'
  // import InfiniteLoading from 'vue-infinite-loading'
  export default {
    name: 'ConceptView',
    metaInfo: {
      title: 'Concept',
      titleTemplate: '%s | FFXIV Housing Eden',
      meta: [
        { charset: 'utf-8' },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1',
        },
        {
          name: 'keywords',
          content:
            'ff14 ハウジング,ハウジング ff14,ff14 housing,housing ff14,ff14ハウジング,ff14housing,ハウジング,ハウジングエデン,ff14,ファイナルファンタジー,housing,ffxiv,final fantasy,Housing Eden,housing eden',
        },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'og:url', content: 'https://e-d-e-n.site' },
        { property: 'og:title', content: 'FFXIV Housing Eden' },
        {
          name: 'description',
          content:
            'FFXIV Housing Edenはファイナルファンタジー14の最新ハウジング情報を世界レベルでお届けするサイトです。今話題のツイートはもちろん、バズツイート、ハウジンガー名鑑、創作家具レシピまで網羅しています。',
        },
        {
          property: 'og:image',
          content: 'https://e-d-e-n.site/twitter_card.jpg?3',
        },
      ],
    },
    components: {
      Tweet,
      Spinner,
    //   Ads,
    // InfiniteLoading,
    },
    data: function () {
      return {
        items: [],
        // selectedId: "",
        sortDesc: true,
        search: '',
        options: {},
        page: 1,
        alert: false,
        targetDate: '',
        tag: '#HousingEdenConcept',
        sns: {
          twitter:
            'https://twitter.com/intent/tweet?hashtags=HousingEdenConcept',
        },
      }
    },
    created: function () {
      // 日付をYYYY-MM-DDの書式で返すメソッド
      function formatDate (dt) {
        var y = dt.getFullYear()
        var m = ('00' + (dt.getMonth() + 1)).slice(-2)
        var d = ('00' + dt.getDate()).slice(-2)
        return (y + '-' + m + '-' + d)
      }
      this.targetDate = new Date()
      this.targetDate = formatDate(new Date(this.targetDate.getFullYear(), this.targetDate.getMonth(), this.targetDate.getDate() - 1))
      console.log(this.targetDate)
      if (!this.$store.conceptItems) {
        axios
          .get(`../../concept.json?timestamp=${new Date().getTime()}`)
          .then((res) => {
            this.items = res.data
            this.$store.conceptItems = this.items
          // res.data.forEach(data => {
          //   let inputdata;
          //   inputdata = data.quoted_status
          //   inputdata.user_name = data.quoted_status.user.name;
          //   inputdata.user_screen_name = data.quoted_status.user.screen_name;
          //   inputdata.user_id_str = data.quoted_status.user.id_str;
          //   this.items.push(inputdata);
          // });
          })
      } else {
        this.items = this.$store.conceptItems
      }

      if (this.$store.conceptOptions) {
        this.options = this.$store.conceptOptions
      }
      this.page = this.$store.conceptPage
    },
    methods: {
      scrollTop: function () {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
        this.$store.conceptPage = event
      },
      // 削除済みツイートの排除
      notShow: function (item) {
        // 指定されたindexの要素を1つ削除します。
        // this.items.splice(index, 1)
        var result = this.items.filter(function (i) {
          console.log('test1')
          return i !== item
        })
        console.log('test2')
        this.items = result
      },
      optionsSave: function (event) {
        // console.log(event)
        this.$store.conceptOptions = event
      },
      onCopy: function (e) {
        alert('Copied')
        console.log('You just copied: ' + e.text)
      },
      onError: function (e) {
        console.log('Failed to copy texts')
      },
    },
  // methods: {
  //   changeUser(selectedId) {
  //     //this.$router.push({path: a })
  //     console.log(selectedId);
  //   }
  // }
  }
</script>
